@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.honda-theme {
  --font-sans: "Figtree", sans-serif;

  --color-primary: 234, 0, 41;
  --color-selected-tab: #0d9be4;

  @apply font-main text-ink bg-body;

  .tw-header .tw-sign-in {
    @apply bg-selected-tab;
  }
  .tw-vehicle-header__tire-size-option[aria-selected="true"] {
    @apply bg-ink;
  }

  .tw-checkbox:checked,
  .tw-radiobutton:checked {
    @apply text-primary bg-opacity-100;
  }

  .tw-tire-list {
    .tw-checkbox:checked,
    .tw-radiobutton:checked {
      @apply text-white bg-opacity-100;
    }
  }
}
