@font-face {
  font-family: "Nobel";
  src: url("/static/fonts-v2/Nobel/NobelRegular.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/Nobel/NobelRegular.woff?ck=05032024041234")
      format("woff"),
    url("/static/fonts-v2/Nobel/NobelRegular.ttf") format("truetype"),
    url("/static/fonts-v2/Nobel/NobelRegular.svg") format("svg");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Nobel";
  src: url("/static/fonts-v2/Nobel/NobelBook.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/Nobel/NobelBook.woff?ck=05032024041234")
      format("woff"),
    url("/static/fonts-v2/Nobel/NobelBook.ttf") format("truetype"),
    url("/static/fonts-v2/Nobel/NobelBook.svg") format("svg");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Nobel";
  src: url("/static/fonts-v2/Nobel/NobelLight.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/Nobel/NobelLight.woff?ck=05032024041234")
      format("woff"),
    url("/static/fonts-v2/Nobel/NobelLight.ttf") format("truetype"),
    url("/static/fonts-v2/Nobel/NobelLight.svg") format("svg");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

.toyota-theme {
  --font-sans: "Nobel", sans-serif;

  --color-primary: 225, 10, 29;
  --color-selected-tab: #e10a1d;

  @apply font-main text-ink bg-body;

  .tw-header .tw-sign-in {
    @apply bg-selected-tab;
  }
  .tw-vehicle-header__tire-size-option[aria-selected="true"] {
    @apply bg-ink;
  }

  .tw-checkbox:checked,
  .tw-radiobutton:checked {
    @apply text-primary bg-opacity-100;
  }

  .tw-tire-list {
    .tw-checkbox:checked,
    .tw-radiobutton:checked {
      @apply text-white bg-opacity-100;
    }
  }

  .tw-vehicle-header__tire-size-option[aria-selected="true"],
  .tw-tire-tile__actions {
    @apply bg-primary;
  }
}
