@font-face {
  font-family: "MAZDA";
  src: url("/static/webfontkit-mazda/MazdaType-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "MAZDA";
  src: url("/static/webfontkit-mazda/MazdaType-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

.mazda-theme {
  --border-radius-button: 0.25rem;
  --border-radius-button-group: 0.5rem;
  --border-radius-input: 0.25rem;
  --border-radius-panel: 1.25rem;
  --color-ink: #000000;
  --color-paper-2: 248, 250, 252;
  --color-primary: 145, 10, 45;
  --color-selected-tab: #910a2d;
  --font-sans: "MAZDA", sans-serif;
  --color-ribbon-best: #847465;
  --color-ribbon-better: #9aaaa9;
  --color-ribbon-good: #d0d3da;

  @apply font-main text-ink bg-body;

  &.landing-page {
    @apply bg-paper;
  }

  .tw-header {
    @apply bg-black text-white border-b-0;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.4);
  }

  .tw-header__dealer {
    @apply uppercase;
  }

  .tw-header__nav {
    a {
      color: #94a3b8;
      opacity: 1;
      &:hover {
        opacity: 0.6;
      }
    }
    span {
      @apply inline-block mx-4;
    }
  }

  .tw-sign-in {
    @apply bg-transparent border border-paper uppercase;
  }

  .tw-legal-footer {
    @apply bg-black text-white text-opacity-60;
  }

  .tw-carousel {
    @apply bg-black pb-12 md:pb-16 lg:pb-24;
  }
  .tw-carousel__controls {
    @apply bottom-4 lg:bottom-8;
  }

  .tw-checkbox:checked,
  .tw-radiobutton:checked {
    @apply text-ink bg-opacity-100;
  }

  .tw-vehicle-header__tire-size-option[aria-selected="true"] {
    @apply bg-ink;
  }

  .tw-tire-list {
    .tw-checkbox:checked,
    .tw-radiobutton:checked {
      @apply text-white bg-opacity-100;
    }
  }

  #tire-manufacturers {
    @apply saturate-0;
  }

  .tw-button--primary {
    @apply bg-ink border-ink text-paper;
    & > :first-child {
      z-index: 1;
    }
  }

  .tw-search-form .tw-button--primary,
  #create-package-button-review {
    @apply bg-transparent border border-ink text-ink;
  }

  .tw-button {
    @apply focus:ring-0;
  }

  .tw-button--primary::before {
    @apply bg-primary opacity-100 z-0 scale-x-0;
  }

  .tw-weel-tile__actions,
  .tw-weel-accessory__actions {
    @apply bg-black;
  }

  .tw-weel-tile__actions .tw-button,
  .tw-weel-accessory__actions .tw-button {
    @apply text-paper;
  }

  .tw-weel-tile__actions--selected {
    @apply bg-primary text-paper border-paper;
  }
  .tw-weel-tile__actions--selected .tw-button {
    @apply bg-primary;
  }

  .tw-weel-tile__actions--selected .tw-button--primary::before {
    @apply bg-paper opacity-5;
  }

  @media (hover: hover) {
    .tw-button--primary {
      &:hover {
        @apply text-paper border-primary;
        &::before {
          @apply scale-x-100;
        }
        & > :first-child {
          @apply text-paper;
        }
      }
    }
  }
}
