.mb-lp-header {
  @apply flex flex-col items-center pt-8 pb-2;
  @apply md:gap-6 md:pt-16 md:pb-6;

  h2 {
    @apply text-xs font-thin md:text-base;
  }

  h1 {
    @apply font-serif text-xl md:text-4xl;
  }
}

.mb-lp-blocks {
  @apply grid gap-8 md:gap-x-8 md:gap-y-[2.6rem] md:grid-cols-2;
}

.mb-lp-figure {
  @apply grid gap-4 text-sm md:text-lg;
  h2 {
    @apply flex items-center gap-2 text-primary pb-2 md:pb-4;
    font-size: 1.25em;
    strong {
      @apply font-medium text-ink;
      font-size: 1.4em;
    }
  }
}

.mb-lp-figure--first {
  @apply md:row-span-2;
}
