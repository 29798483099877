@font-face {
  font-family: "BMWTypeGlobalPro";
  src: url("/static/webfontkit-bmw/BMWTypeGlobalPro-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "BMWTypeGlobalPro";
  src: url("/static/webfontkit-bmw/BMWTypeGlobalPro-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

.mini-theme {
  --border-radius-button: 0.25rem;
  --border-radius-button-group: 0.5rem;
  --border-radius-input: 0.25rem;
  --border-radius-panel: 1.25rem;
  --color-accent: 84, 194, 238;
  --color-primary: 84, 194, 238;
  --font-sans: "BMWTypeGlobalPro", sans-serif;

  @apply font-main text-ink bg-body;

  .tw-sign-in {
    @apply border border-ink text-ink bg-transparent uppercase;
  }

  .tw-header__dealer {
    @apply uppercase;
  }

  .tw-carousel {
    @apply bg-black pb-12 md:pb-16 lg:pb-24;
  }
  .tw-carousel__controls {
    @apply bottom-4 lg:bottom-8;
  }

  .tw-search-form {
    @apply shadow-xl;
  }
}
