@font-face {
  font-family: bmwTypeNextWeb;
  src: url("/static/fonts-v2/bmwTypeNextWeb/BMWTypeNextProHebrew-Light.woff2")
      format("woff2"),
    url("/static/fonts-v2/bmwTypeNextWeb/BMWTypeNextProHebrew-Light.woff")
      format("woff");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: bmwTypeNextWeb;
  src: url("/static/fonts-v2/bmwTypeNextWeb/BMWTypeNext-Regular.woff2")
      format("woff2"),
    url("/static/fonts-v2/bmwTypeNextWeb/BMWTypeNext-Regular.woff")
      format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: bmwTypeNextWeb;
  src: url("/static/fonts-v2/bmwTypeNextWeb/BMWTypeNextProHebrew-Bold.woff2")
      format("woff2"),
    url("/static/fonts-v2/bmwTypeNextWeb/BMWTypeNextProHebrew-Bold.woff")
      format("woff");
  font-weight: 700;
  font-display: swap;
}

.bmw-theme {
  --border-radius-button: 0.25rem;
  --border-radius-button-group: 0.5rem;
  --border-radius-input: 0.25rem;
  --border-radius-panel: 1.25rem;
  --color-accent: 0, 102, 177;
  --color-primary: 0, 102, 177;
  --color-selected-tab: #0066b1;
  --font-sans: "bmwTypeNextWeb", sans-serif;

  @apply font-main text-ink bg-body;

  &.landing-page {
    @apply bg-paper;
  }

  .tw-header__dealer {
    @apply uppercase;
  }

  .tw-carousel__controls {
    @apply bottom-4 lg:bottom-8;
  }

  .tw-search-form {
    @apply shadow-xl;
  }

  .tw-vehicle-header__tire-size {
    .tw-vehicle-header__tire-size-option {
      &[aria-selected="true"] {
        @apply bg-ink text-paper;
      }
    }
  }

  .tw-tire-tile__actions {
    @apply bg-primary;
  }
  .tw-tire-tile__actions--selected {
    @apply bg-ink;
  }

  #tire-manufacturers {
    @apply saturate-0;
  }
}
