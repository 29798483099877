@font-face {
  font-family: "Nissan";
  src: url("/static/fonts-v2/Nissan/NissanBrandW01-Light.woff2") format("woff2"),
    url("/static/fonts-v2/Nissan/NissanBrandW01-Light.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Nissan";
  src: url("/static/fonts-v2/Nissan/NissanBrandW01-Regular.woff2")
      format("woff2"),
    url("/static/fonts-v2/Nissan/NissanBrandW01-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Nissan";
  src: url("/static/fonts-v2/Nissan/NissanBrandW01-Bold.woff2") format("woff2"),
    url("/static/fonts-v2/Nissan/NissanBrandW01-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

.nissan-theme {
  --font-sans: "Nissan", sans-serif;

  --color-primary: 195, 0, 47;

  @apply font-main text-ink bg-body;
}
