@font-face {
  font-family: "HyundaiSansHead";
  src: url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Bold.ttf")
      format("truetype"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Bold.woff2")
      format("woff2"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Bold.woff") format("woff");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "HyundaiSansHead";
  src: url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Medium.ttf")
      format("truetype"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Medium.woff2")
      format("woff2"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Medium.woff")
      format("woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "HyundaiSansHead";
  src: url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Regular.ttf")
      format("truetype"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Regular.woff2")
      format("woff2"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansHead-Regular.woff")
      format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "HyundaiSansText";
  src: url("/static/fonts-v2/HyundaiSans/HyundaiSansText-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansText-Medium.ttf")
      format("truetype"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansText-Medium.woff2")
      format("woff2"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansText-Medium.woff")
      format("woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "HyundaiSansText";
  src: url("/static/fonts-v2/HyundaiSans/HyundaiSansText-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansText-Regular.ttf")
      format("truetype"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansText-Regular.woff2")
      format("woff2"),
    url("/static/fonts-v2/HyundaiSans/HyundaiSansText-Regular.woff")
      format("woff");
  font-weight: 400;
  font-display: swap;
}

.hyundai-theme {
  --border-radius-button: 0;
  --border-radius-button-group: 0;
  --border-radius-checkbox: 0;
  --border-radius-checkbox-check: 0;
  --border-radius-dropdown: 0;
  --border-radius-input: 0;
  --border-radius-panel: 0;
  --border-radius-panel-section: 0;
  --border-radius-section: 0;
  --border-radius-tw-lg: 0;
  --border-radius-tw-full: 0;
  --color-accent: 228, 220, 211;
  --color-edge: 228, 220, 211;
  --color-primary: 0, 44, 107;
  --color-selected-tab: #00aad2;
  --font-sans: "HyundaiSansText", sans-serif;

  @apply font-main text-ink bg-body;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "HyundaiSansHead", sans-serif;
  }

  hr {
    @apply border-edge;
  }

  .tw-header {
    @apply bg-white;
    .tw-header__nav {
      gap: 0.5rem;
      text-transform: none;
    }
    #sign-in-toggle {
      @apply bg-transparent text-ink;
    }
  }

  .tw-tabs-group {
    @apply border-none;
    > button,
    > a {
      @apply text-selected-tab;
      &[aria-selected="true"] {
        @apply text-paper;
      }
    }
    .tw-tabs-paddle {
      @apply h-full;
    }
  }

  .tw-search-form,
  .tw-landing-form {
    .tw-input,
    .tw-input-group,
    .tw-select {
      @apply border-none shadow-lg bg-paper;
      span:first-child {
        @apply hidden;
      }
    }
    .tw-input-group {
      .tw-input,
      tw-select {
        @apply shadow-none bg-transparent;
      }
    }
  }

  .tw-vehicle-header__tire-size {
    @apply gap-[0.5px] p-[0.5px] bg-selected-tab;
    .tw-vehicle-header__tire-size-option {
      @apply border-none text-selected-tab bg-body;
      &[aria-selected="true"] {
        @apply bg-selected-tab text-paper;
      }
    }
  }

  .tw-tire-tile__actions {
    @apply grid bg-accent;
    .tw-create-package-button {
      @apply order-1 p-5 bg-paper text-ink w-full;
    }
    .tw-compare-button {
      @apply order-2 p-4 bg-paper text-ink w-full;
    }
    .tw-cart-button {
      @apply order-3 bg-primary;
    }
  }
  .tw-tire-tile__actions:has(> *:nth-last-child(3)) {
    @apply grid-cols-2;
    .tw-cart-button {
      @apply col-span-2;
    }
    .tw-compare-button {
      @apply ml-0;
    }
  }

  .tw-weel-tile--selected {
    @apply border-selected-tab;
    .tw-weel-tile__selected {
      @apply bg-selected-tab;
    }
  }

  .tw-package-tile--selected {
    @apply border-selected-tab;
  }
  .tw-package-tile__cart-button {
    @apply bg-selected-tab;
  }

  .tw-header__dealer {
    @apply uppercase;
  }

  .tw-carousel {
    @apply bg-black;
  }

  .tw-search-form {
    @apply shadow-xl;
  }
}
