@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.ford-theme {
  --font-sans: "Figtree", sans-serif;

  --color-primary: 0, 9, 91;
  --color-selected-tab: #00095b;
  --color-accent: 0, 104, 208;

  @apply font-main text-ink bg-body;

  .tw-sign-in {
    @apply border border-primary bg-transparent text-primary;
  }

  .tw-checkbox:checked,
  .tw-radiobutton:checked {
    @apply text-primary bg-opacity-100;
  }

  .tw-tire-tile__actions {
    @apply bg-primary;

    .tw-checkbox:checked {
      @apply text-paper bg-opacity-100;
    }
  }
  .tw-tire-tile__actions--selected {
    @apply bg-accent;
  }
}
