/* NOTE: import oreder matters */
@import "../ui/ui.css";
@import "../blocks/blocks.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  .tw-layout {
    @apply flex flex-col min-h-screen;
    min-height: 100dvh;
  }

  .text-wrap-balance,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-wrap: balance;
  }

  h1 {
    @apply text-xl sm:text-2xl md:text-3xl font-medium;
  }

  div,
  p {
    text-wrap: pretty;
  }

  .tw-print-color-adjust {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .tw-highlight {
    @apply bg-yellow-100 rounded;
  }

  .tw-rc-off div:has(> .grecaptcha-badge) {
    display: none;
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .grid-cols-24 {
    grid-template-columns: repeat(24, minmax(0, 1fr));
  }
}
