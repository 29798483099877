@font-face {
  font-family: "Nobel";
  src: url("/static/fonts-v2/Nobel/NobelRegular.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/Nobel/NobelRegular.woff?ck=05032024041234")
      format("woff"),
    url("/static/fonts-v2/Nobel/NobelRegular.ttf") format("truetype"),
    url("/static/fonts-v2/Nobel/NobelRegular.svg") format("svg");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Nobel";
  src: url("/static/fonts-v2/Nobel/NobelBook.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/Nobel/NobelBook.woff?ck=05032024041234")
      format("woff"),
    url("/static/fonts-v2/Nobel/NobelBook.ttf") format("truetype"),
    url("/static/fonts-v2/Nobel/NobelBook.svg") format("svg");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Nobel";
  src: url("/static/fonts-v2/Nobel/NobelLight.eot?#iefix")
      format("embedded-opentype"),
    url("/static/fonts-v2/Nobel/NobelLight.woff?ck=05032024041234")
      format("woff"),
    url("/static/fonts-v2/Nobel/NobelLight.ttf") format("truetype"),
    url("/static/fonts-v2/Nobel/NobelLight.svg") format("svg");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

.lexus-theme {
  --font-sans: "Nobel", sans-serif;

  --border-radius-button: 0.25rem;
  --border-radius-button-group: 0.5rem;
  --border-radius-input: 0.25rem;
  --border-radius-panel: 1.25rem;

  --color-primary: 15, 23, 42;

  @apply font-main text-ink bg-body;

  .tw-sign-in {
    @apply border-2 bg-paper text-ink;
  }

  .tw-checkbox:checked,
  .tw-radiobutton:checked {
    @apply text-primary bg-opacity-100;
  }

  .tw-button {
    @apply uppercase;
  }

  .tw-tire-list {
    .tw-checkbox:checked,
    .tw-radiobutton:checked {
      @apply text-white bg-opacity-100;
    }
  }

  .tw-header__dealer {
    @apply uppercase border-l-[0.5px] border-ink pl-4 font-bold ml-4 py-[0.25rem];
  }

  #tire-manufacturers {
    @apply saturate-0;
  }
}
