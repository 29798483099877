@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.mercedes-theme {
  --border-radius-button: 0.25rem;
  --border-radius-button-group: 0.5rem;
  --border-radius-input: 0.25rem;
  --border-radius-panel: 1.25rem;
  --color-accent: 0, 173, 239;
  --color-primary: 46, 124, 190;
  --font-sans: "Figtree", sans-serif;

  @apply font-main text-ink bg-body;

  &.landing-page {
    @apply bg-paper;
  }

  .tw-header {
    @apply bg-black text-white border-b-0;
  }
  .tw-header__container {
    @apply md:h-14;
  }

  .tw-header__dealer {
    @apply uppercase;
  }

  .tw-header__controls {
    @apply flex-row items-center justify-end gap-4;
    .tw-header__nav {
      @apply mb-0;
    }
  }

  .tw-tabs-group {
    > button,
    > a {
      @apply text-selected-tab;
      &[aria-selected="true"] {
        @apply text-paper;
      }
    }
    .tw-tabs-paddle {
      @apply h-full;
    }
  }

  .tw-legal-footer {
    @apply bg-black text-white text-opacity-60;
  }

  .tw-carousel {
    @apply bg-black pb-12 md:pb-16 lg:pb-24;
  }
  .tw-carousel__controls {
    @apply bottom-4 lg:bottom-8;
  }

  .tw-search-form {
    @apply shadow-xl;
  }

  .tw-vehicle-header__tire-size-option[aria-selected="true"] {
    @apply bg-primary;
  }

  #tire-manufacturers {
    @apply saturate-0;
  }
}
